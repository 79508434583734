<template>
  <div class="px-4">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      min-width="290px"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dates"
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-calendar"
          class="text-sm date-select"
          label="Filtreaza"
          readonly
          single-line
        ></v-text-field>
      </template>
      <v-date-picker v-model="dates" color="#60a629" no-title range scrollable>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="primary-color" text  @click="$refs.menu.save(dates)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>

  </div>
</template>

<script>
export default {
  name: 'DateFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      options: [],
      selectOption: '',
      menu: false,
      dates: []
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join('|')
    }
  },
  watch: {
    dates (newVal) {
      if (newVal.length > 1) {
        this.$emit('filter', this.dateRangeText, this.filterName)
      }
    }
  }
}
</script>

<style scoped>
</style>
