<template>
  <div class="select-filter">
    <v-autocomplete
    v-model="selectOption"
    :items="customers"
    :label="label"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    clearable
    dense
    hide-details
    item-text="client_display_name"
    item-value="id"
    outlined
    single-line
  >
    <template v-slot:item="{item}">
      <v-list-item-content>
        {{ item.client_display_name }}
        <small v-if="item.is_reseller">(client Distributie)</small>
        <small v-else>(client End User)</small>
      </v-list-item-content>
    </template>
  </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'CustomerSelector',
  props: {
    value: {},
    label: {
      default: 'Client'
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    customers () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/clients?filter[client_display_name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
