<template>
    <customer-selector
        v-model="selectOption"
    />
</template>

<script>
import CustomerSelector from '@/components/autocompletes/CustomerSelector'
export default {
  name: 'CustomerFilter',
  components: {CustomerSelector},
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  watch: {
    selectOption () {
      const value = this.selectOption || ''
      this.$emit('filter', value, this.filterName)
    }
  }
}
</script>
